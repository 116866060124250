import loginLogo from '@/assets/images/login-logo.svg';
import { LoginForm } from './LoginForm';

//todo: footer links

export const Login = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-y-12">
      <img src={loginLogo} alt="Login Logo" />
      <LoginForm />
      <footer className="fixed bottom-0 w-full text-center my-7 text-sm">
        © 2024 CrossnoKaye Inc. All rights reserved. <a href="">Privacy Policy</a> |{' '}
        <a href="">Terms of Service</a>
      </footer>
    </div>
  );
};
