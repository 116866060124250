import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Login } from '@/components/Login/index';
import { cn } from '@crossnokaye/ux-common';
const queryClient = new QueryClient();

const getRandomOption = () => {
  const options = [
    'bg-login-background-1',
    'bg-login-background-2',
    'bg-login-background-3',
    'bg-login-background-4',
  ];
  const randomIndex = Math.floor(Math.random() * options.length);
  return options[randomIndex];
};

function App() {
  const randomOption = getRandomOption();

  return (
    <QueryClientProvider client={queryClient}>
      <div className={cn('dark bg-cover bg-center', randomOption)}>
        <Login />
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
