import {
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@crossnokaye/ux-common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useLogin } from '@/hooks';
import { LoginButton } from './LoginButton';

const loginSchema = z.object({
  username: z.string().email({ message: 'Please enter a valid email' }),
  password: z.string().optional(),
  remember: z.boolean().default(false).optional(),
});

export const rememberMeLocalStorageKey = 'login.rememberMe';

// src/components/LoginPage.jsx
export const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const storedEmail = localStorage.getItem(rememberMeLocalStorageKey);

  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: storedEmail ?? '',
      password: '',
      remember: storedEmail !== null,
    },
  });

  const { isLoading, isSuccess } = useLogin({ username, password });

  function onSubmit(data: z.infer<typeof loginSchema>) {
    setUsername(data.username);
    if (data.password) {
      setPassword(data.password);
    }
  }

  function setRememberMeStorageValue(username: string) {
    localStorage.setItem(rememberMeLocalStorageKey, username);
  }

  function onRememberMeChange(checked: boolean) {
    form.setValue('remember', checked);
    if (checked) {
      setRememberMeStorageValue(form.getValues('username'));
    } else {
      localStorage.removeItem(rememberMeLocalStorageKey);
    }
  }

  function onUsernameChange(value: string) {
    form.setValue('username', value);
    if (form.getValues('remember')) {
      setRememberMeStorageValue(value);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col items-center gap-y-6 min-w-80 md:min-w-96"
        data-testid="login-form"
        style={{ zIndex: 1 }}
      >
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel htmlFor="username">User Email</FormLabel>
              <FormControl>
                <Input
                  autoComplete="username"
                  {...field}
                  onChange={(e) => onUsernameChange(e.target.value)}
                  data-testid="email-input"
                />
              </FormControl>
              <FormMessage>{form.formState.errors.username?.message}</FormMessage>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel htmlFor="password">Password</FormLabel>
              <FormControl>
                <Input
                  autoComplete="current-password"
                  type="password"
                  {...field}
                  data-testid="password-input"
                />
              </FormControl>
              <FormMessage>{form.formState.errors.password?.message}</FormMessage>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="remember"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Checkbox
                  id="remember"
                  checked={field.value}
                  onCheckedChange={onRememberMeChange}
                  data-testid="remember-me-checkbox"
                />
              </FormControl>
              <FormLabel htmlFor="remember" className="ml-2">
                Remember me next time I sign in
              </FormLabel>
            </FormItem>
          )}
        />
        <LoginButton isSuccess={isSuccess} isLoading={isLoading} />
      </form>
    </Form>
  );
};
