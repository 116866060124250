import { UseQueryResult, useQuery } from '@tanstack/react-query';

export interface LoginData {
  username: string;
  password?: string;
}

export const useLogin = (data: LoginData): UseQueryResult<LoginData> => {
  const { username } = data;
  return useQuery({
    queryKey: ['login', { username }],
    queryFn: async () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log('Logging in as', username);
          resolve({ data });
        }, 2000); // Resolve after 2 seconds
      });
    },
    enabled: username !== '',
  });
};
