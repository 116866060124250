import { Button } from '@crossnokaye/ux-common';
import { Check, Loader2 } from 'lucide-react';
import { FC } from 'react';

export interface LoginButtonProps {
  isSuccess: boolean;
  isLoading: boolean;
}

export const LoginButton: FC<LoginButtonProps> = ({ isSuccess, isLoading }) => {
  return (
    <div data-testid="login-button" className="transition-all duration-300 w-full">
      <Button
        type="submit"
        className="uppercase flex justify-center items-center w-full font-bold"
        disabled={isLoading}
      >
        {isLoading && (
          <>
            <Loader2 data-testid="loading-spinner" className="mr-2 h-4 w-4 animate-spin" />
            Logging in...
          </>
        )}
        {!isLoading && !isSuccess && 'sign in'}
        {isSuccess && <Check data-testid="success-check" />}
      </Button>
    </div>
  );
};
